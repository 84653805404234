$color-primary-600: #005b8e;
$color-primary-500: #007cc1;
$color-primary-400: #009df4;
$color-secondary-650: #50585c;
$color-secondary-500: #737f85;
$color-secondary-400: #8e989d;
$color-accent-600: #e36a00;
$color-accent-500: #f39200;
$color-accent-400: #ffa927;

$color-neutral-0: #fff;
$color-neutral-20: #f6f7f7;
$color-neutral-50: #eeeff0;
$color-neutral-300: #B1B8BC;
$color-neutral-400: #8e989d;
$color-neutral-800: #2c3133;
