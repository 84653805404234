@use 'colors';
@use 'sass:math';

$base-font-size: 14;

html {
  color: colors.$color-secondary-650;
  font-family: 'Open Sans', sans-serif;
  font-size: $base-font-size * 1px;
  line-height: 1.4;
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/open-sans-v34-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/open-sans-v34-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src: url('./fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/open-sans-v34-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/open-sans-v34-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/open-sans-v34-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}

h1 {
  font-size: math.div(34.8, $base-font-size) + rem;
  line-height: 1.2;
  margin-bottom: 24px;
}

h2 {
  font-size: math.div(29, $base-font-size) + rem;
  line-height: 1.2;
  margin-bottom: 24px;
}

h3 {
  font-size: math.div(24.2, $base-font-size) + rem;
  line-height: 1.3;
  margin-bottom: 16px;
}

h4 {
  font-size: math.div(20.2, $base-font-size) + rem;
  font-weight: 600;
  line-height: 1.3;
}

h5 {
  font-size: math.div(16.8, $base-font-size) + rem;
  font-weight: 700;
  line-height: 1.4;
}

h6 {
  font-size: math.div(14, $base-font-size) + rem;
  font-weight: 700;
  line-height: 1.4;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th,
  tr,
  td {
    padding: 16px 8px;
  }

  thead {
    background-color: colors.$color-neutral-50;
    color: colors.$color-secondary-650;
    font-weight: 700;

    th {
      font-weight: 600;
    }

    th + th {
      padding-left: 16px;
    }
  }

  tbody {
    color: colors.$color-neutral-800;

    tr:nth-child(even) {
      background-color: colors.$color-neutral-20;
    }
    tr:nth-child(odd):last-child {
      border-bottom: 1px solid colors.$color-neutral-20;
    }
    td + td {
      padding-left: 16px;
    }
  }
}
